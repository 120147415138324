import React, { useState } from 'react';
import Button from './common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

type NewsItem = {
  id: string;
  title: string;
  excerpt: string;
  slug: string;
  featuredImage: {
    sizes: {
      src: string;
    };
  };
};

type BlogPost = {
  title: string;
  slug: string;
  featuredImage: {
    sizes: {
      src: string;
    };
  };
};

interface NewsSliderProps {
  newsItems: NewsItem[];
  blogPosts: BlogPost[];
}
const NewsSlider: React.FC<NewsSliderProps> = props => {
  const { blogPosts, newsItems } = props;

  const sliderItems = [
    ...blogPosts.map((p: BlogPost) => ({ ...p, type: 'BLOG_POST' })),
    ...newsItems.map((n: NewsItem) => ({ ...n, type: 'NEWS_ITEM' }))
  ];

  const [currentItem, setCurrentItem] = useState(sliderItems[0]);

  if (!currentItem) {
    return null;
  }

  return (
    <section className="bg-white rounded-lg shadow-lg p-4">
      <div className="flex">
        {sliderItems.length > 1 && (
          <button
            className="text-primary p-1 mr-3 rounded-lg focus:outline-none"
            onClick={() => {
              const currentIndex = sliderItems.findIndex(
                (item: any) => item.title === currentItem.title
              );
              const prevIndex =
                currentIndex > 0 ? currentIndex - 1 : sliderItems.length - 1;

              setCurrentItem(sliderItems[prevIndex]);
            }}
          >
            <span className="sr-only">Back</span>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        )}
        <div className="flex w-full">
          <div className="w-1/4">
            <img
              src={
                currentItem?.featuredImage?.sizes?.src ||
                require(`./../images/news-fallback.png`)
              }
              alt="news image"
              className="w-32 rounded-lg m-auto"
            />
          </div>
          <div className="w-3/4">
            <div className="ml-6">
              <h3 className="text-xl font-semibold text-gray-700 mb-1  ">
                {currentItem.title}
              </h3>
              <div className="mt-2">
                <Link
                  to={`/${currentItem.type === 'BLOG_POST' ? 'blog' : 'news'}/${
                    currentItem.slug
                  }`}
                >
                  <Button size="sm" text="Read More" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {sliderItems.length > 1 && (
          <button
            className="text-primary p-1 ml-3 rounded-lg focus:outline-none"
            onClick={() => {
              const currentIndex = sliderItems.findIndex(
                (item: any) => item.title === currentItem.title
              );
              const nextIndex =
                currentIndex === sliderItems.length - 1 ? 0 : currentIndex + 1;

              setCurrentItem(sliderItems[nextIndex]);
            }}
          >
            <span className="sr-only">Next</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        )}
      </div>
    </section>
  );
};

export default NewsSlider;
