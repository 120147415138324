import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

interface ServiceCardProps {
  title: string;
  body: string;
  image: any;
  link: string;
  linkLabel: string;
}

const ServiceCard: React.FC<ServiceCardProps> = props => {
  return (
    <div className="flex flex-col h-full">
      <Img
        className="rounded-lg self-center w-full mb-6 shadow-xl h-72"
        fluid={props.image.childImageSharp.sizes}
      />
      <h3 className="text-primary font-bold text-2xl mb-2 text-center">
        {props.title}
      </h3>
      <div
        className="text-gray-500 text-lg mt-4 self-center mb-4"
        dangerouslySetInnerHTML={{ __html: props.body }}
      />
      <div className="mt-auto">
        <Link to={props.link} className="text-primary font-bold text-lg">
          View {props.linkLabel} &rarr;
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;
