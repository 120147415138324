import React from 'react';
import { Link } from 'gatsby';
import BlogPostCard from './BlogPostCard';
import Button from './common/Button';

interface BlogPost {
  title: string;
  featuredImage: {
    fluid: {
      src: string;
    };
  };
  slug: string;
  publishedDate: string;
  author: {
    firstName: string;
    lastName: string;
    image: {
      fluid: {
        src: string;
      };
    };
  };
}

interface BlogPostSectionProps {
  blogPosts: BlogPost[];
  blogFallbackImage: any;
}

const BlogPostsSection: React.FC<BlogPostSectionProps> = props => {
  return (
    <>
      <div className="container m-auto">
        <div className="flex flex-wrap sm:flex-nowrap justify-center">
          {props.blogPosts.map((post: BlogPost, i: number) => {
            return (
              <div className="flex m-4" key={i}>
                <BlogPostCard
                  title={post.title}
                  image={
                    post.featuredImage?.fluid?.src ??
                    props.blogFallbackImage.childImageSharp.sizes.src
                  }
                  author={post.author}
                  publishedDate={post.publishedDate}
                  link={`/blog/${post.slug}`}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="text-center mt-8">
        <Link to="/blog">
          <Button text="View More Blog Posts" size="md" />
        </Link>
      </div>
    </>
  );
};

export default BlogPostsSection;
