import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import BlogPostsSection from '../components/BlogPostsSection';
import BottomBanner from '../components/BottomBanner';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import MailingListSignup from '../components/MailingListSignup';
import NewsSlider from '../components/NewsSlider';
import Resources from '../components/Resources';
import SectionHeader from '../components/SectionHeader';
import SEO from '../components/Seo';
import Services from '../components/Services';
import SocialLinks from '../components/SocialLinks';
import SocialMedia from '../components/SocialMedia';
import UpcomingEvents from '../components/UpcomingEvents';
import Spacer from '../components/common/Spacer';
import { extractContent } from './../util';
config.autoAddCss = false;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulEvent(
        filter: { node_locale: { eq: "en-US" } }
        sort: { fields: startDate, order: ASC }
      ) {
        edges {
          node {
            id
            title
            startDate
            endDate
            description {
              description
            }
            slug
            price
            image {
              sizes(maxWidth: 600) {
                base64
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
      allContentfulResource(
        limit: 12
        filter: { showOnHomepage: { eq: true }, node_locale: { eq: "en-US" } }
        sort: { fields: homepageOrder, order: ASC }
      ) {
        edges {
          node {
            title
            body {
              body
            }
            image {
              fluid {
                src
              }
            }
            type
            slug
            featuredResources {
              title
            }
          }
        }
      }
      # allContentfulNewsItem(
      #   filter: { showOnHomepage: { eq: true }, node_locale: { eq: "en-US" } }
      # ) {
      #   edges {
      #     node {
      #       title
      #       slug
      #     }
      #   }
      # }
      blogPostsForSlider: allContentfulBlogPost(
        filter: { showOnHomepage: { eq: true }, node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            title
            slug
            featuredImage {
              sizes {
                src
              }
            }
          }
        }
      }
      allContentfulBlogPost(
        filter: { node_locale: { eq: "en-US" } }
        sort: { fields: publishedDate, order: DESC }
        limit: 4
      ) {
        edges {
          node {
            id
            featuredImage {
              fluid {
                src
              }
            }
            title
            publishedDate
            createdAt
            slug
            author {
              firstName
              lastName
              image {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
      blogFallbackImage: file(
        relativePath: { eq: "images/news-fallback.png" }
      ) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      card1Image: file(relativePath: { eq: "images/info-card-1.png" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      card2Image: file(relativePath: { eq: "images/info-card-2.png" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      card3Image: file(relativePath: { eq: "images/info-card-3.png" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      facebookGroupsImage: file(
        relativePath: { eq: "images/classroom-bg.jpg" }
      ) {
        childImageSharp {
          sizes(maxWidth: 300) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      bgImage: file(relativePath: { eq: "images/teachers-college-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(relativePath: { eq: "images/logo-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const events = data.allContentfulEvent.edges
    .map((e: any) => e.node)
    .filter((e: any) => new Date(e.endDate) >= new Date());

  const blogPostsForSlider = extractContent(data.blogPostsForSlider);
  // const newsItems = extractContent(data.allContentfulNewsItem);
  const blogPosts = extractContent(data.allContentfulBlogPost);
  const resources = extractContent(data.allContentfulResource);

  const [showBanner, setShowBanner] = React.useState(false);

  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <Spacer x={10} y={10}>
        <div className="w-96 sm:w-192 m-auto">
          <NewsSlider newsItems={[]} blogPosts={blogPostsForSlider} />
        </div>
      </Spacer>
      <Spacer x={10} y={20}>
        <SectionHeader header="Embracing the Continual Process of Learning" />
        <div className="mt-8">
          <Services data={data} />
        </div>
      </Spacer>
      <UpcomingEvents events={events} />
      <Spacer x={10} y={20}>
        <SectionHeader
          superheader=""
          header="Resources for Teachers, School Leaders, and Families"
        />
        <section className="my-10">
          <Resources resources={resources} />
        </section>
      </Spacer>
      <BackgroundImage fluid={data.bgImage.childImageSharp.fluid}>
        <div className="px-10 py-6 white-to-transparent shadow-lg">
          <SectionHeader superheader="" header="The Latest From Our Blog" />
          <section className="my-4">
            <BlogPostsSection
              blogPosts={blogPosts}
              blogFallbackImage={data.blogFallbackImage}
            />
          </section>
        </div>
      </BackgroundImage>
      <div>
        <Spacer x={10} y={20}>
          <SectionHeader
            superheader=""
            header="Connect with us on Social Media"
          />
          <div className="my-4">
            <SocialLinks />
          </div>
        </Spacer>
      </div>
      <div className="bg-primary">
        <div className="py-5 px-20">
          <MailingListSignup />
        </div>
      </div>
      {showBanner ? (
        <BottomBanner
          onClose={() => setShowBanner(false)}
          body={`Teachers College Reading and Writing Project is excited to transition to Advancing Literacy at Teachers College. We will be continuing our work with schools and educators across the country and are excited to expand and continue learning and growing from the latest research and practices out there. Across the next few months, we will be updating all of our materials to reflect this new name.
          
Join us for our first TC reunion as Advancing Literacy!`}
          image={data.logo}
          link="/events/october-2023-saturday-reunion-virtual"
        />
      ) : null}
    </Layout>
  );
};

export default IndexPage;
