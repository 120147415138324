import React, { useCallback, useState } from 'react';
import LoadingButton from './common/LoadingButton';
import { Formik, Form, Field } from 'formik';

interface MailingListFieldInterface {
  label: string;
  placeholder: string;
  name: string;
}

const MailingListField = (props: MailingListFieldInterface) => {
  return (
    <div className="flex flex-col">
      <label
        htmlFor={props.name}
        className="text-blue-100 sans-serif sm:text-xl font-bold ml-4"
      >
        {props.label}
      </label>
      <Field
        className="rounded-full p-4 bg-primary-dark mt-2 text-blue-200 text-lg focus:outline-none mb-3 sm:mb-0"
        placeholder={props.placeholder}
        id={props.name}
        name={props.name}
      />
    </div>
  );
};

const MailingListSignup: React.FC = () => {
  const [submitState, setSubmitState] = useState('IDLE');
  const submitForm = useCallback(async values => {
    try {
      setSubmitState('SUBMITTING');
      const response = await fetch(
        `${process.env.GATSBY_FUNCTIONS_PATH}/mailingListSignup`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: values.name,
            email: values.email
          })
        }
      );

      if (!response.ok) {
        setSubmitState('SUBMIT_ERROR');
        return;
      }

      const data = await response.json();

      if (data.Code) {
        setSubmitState('SUBMIT_ERROR');
      } else {
        setSubmitState('SUBMIT_SUCCESS');
      }
    } catch (err) {
      setSubmitState('SUBMIT_ERROR');
    }
  }, []);

  return (
    <div className="rounded-lg py-10 sm:py-20 sm:px-10">
      <h3
        id="mailing-list"
        className="text-white text-3xl font-bold text-center"
      >
        Join the Mailing List
      </h3>
      <p className="text-white text-center text-xl mt-6">
        Sign up to join our community of readers and writers
      </p>
      <Formik
        initialValues={{
          name: '',
          email: ''
        }}
        onSubmit={values => {
          submitForm(values);
        }}
      >
        <Form className="flex flex-col md:flex-row mt-10 w-full md:w-3/4 m-auto">
          <div className="w-full md:w-5/12 mr-8">
            <MailingListField label="Name" placeholder="John Doe" name="name" />
          </div>
          <div className="w-full md:w-5/12 mr-8">
            <MailingListField
              label="Email"
              placeholder="john@doe.com"
              name="email"
            />
          </div>
          <div className="w-full md:w-2/12 self-end mb-1 ">
            <LoadingButton
              type="submit"
              text="Submit"
              size="md"
              isLoading={submitState === 'SUBMITTING'}
            />
          </div>
        </Form>
      </Formik>
      <div className="mt-6 w-1/2 m-auto">
        {submitState === 'SUBMIT_SUCCESS' && (
          <div className="p-4 bg-green-300 border border-green-700 rounded-lg">
            <p className="text-green-700 text-center">
              Thanks for joining our mailing list! You've been successfully
              added.
            </p>
          </div>
        )}
        {submitState === 'SUBMIT_ERROR' && (
          <div className="p-4 bg-red-300 border border-red-700 rounded-lg">
            <p className="text-red-700 text-center">
              There was a problem adding you to the mailing list. Please try
              again.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MailingListSignup;
