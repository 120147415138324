import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { getEventDateRange, groupEventsByMonth } from './../util';
import Button from './common/Button';
import { Event } from './Event';
import BackgroundImage from 'gatsby-background-image';

interface MonthPillProps {
  title: string;
}

const MonthPill = (props: MonthPillProps) => {
  return (
    <div className="w-1/2 bg-gradient p-3 rounded-r-full flex">
      <p className="m-auto font-semibold text-blue-100">{props.title}</p>
    </div>
  );
};

interface EventPillProps {
  event: Event;
  isSelected: boolean;
  onClick: any;
}

const EventPill = (props: EventPillProps) => {
  const classNames = classnames({
    'cursor-pointer hover:bg-gray-300 px-8 py-4 border-b border-gray-300': true,
    'bg-gray-300': props.isSelected
  });
  return (
    <div className={classNames} onClick={props.onClick}>
      <p className="font-bold text-primary mb-1 text-sm">{props.event.title}</p>
      <p className="text-gray-700 text-xs font-semibold ">
        <span>
          {getEventDateRange(
            new Date(props.event.startDate),
            new Date(props.event.endDate)
          )}
        </span>
      </p>
    </div>
  );
};

interface PillSpacerProps {
  children: React.ReactNode;
}

const PillSpacer = (props: PillSpacerProps) => {
  return <div className="mb-3">{props.children}</div>;
};

interface UpcomingEventsProps {
  events: Event[];
}

const UpcomingEvents: React.FC<UpcomingEventsProps> = props => {
  const eventsByMonth = groupEventsByMonth(props.events.slice(0, 5));
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(
    eventsByMonth[0] ? eventsByMonth[0].events[0] : null
  );

  return (
    <div className="bg-primary w-full h-full flex flex-col sm:flex-row">
      <div className="w-full sm:w-1/2 py-8">
        <p className="text-blue-100 text-2xl text-center mx-8 sm:mx-0 font-bold mb-8">
          Professional Development Events
        </p>
        {eventsByMonth.map((eventMonth, i) => (
          <div key={i}>
            <PillSpacer>
              <MonthPill title={eventMonth.month} />
            </PillSpacer>
            <div className="bg-gray-50 mb-3">
              {eventMonth.events.map((event: Event, i) => (
                <EventPill
                  event={event}
                  isSelected={
                    selectedEvent ? selectedEvent.id === event.id : false
                  }
                  onClick={() => setSelectedEvent(event)}
                  key={i}
                />
              ))}
            </div>
          </div>
        ))}
        <div className="text-center mt-8">
          <Link to="/events">
            <Button text="View More Events" size="md" />
          </Link>
        </div>
      </div>

      <div className="w-full sm:w-1/2 relative">
        {selectedEvent && (
          <>
            <Img
              fluid={selectedEvent?.image?.sizes}
              className="w-full h-full"
            />
            <div className="p-10 bg-black bg-opacity-75 w-full sm:absolute inset-x-0 bottom-0">
              <p className="text-white font-bold text-2xl">
                {selectedEvent.title}
              </p>
              <p className="text-white">
                {getEventDateRange(
                  new Date(selectedEvent.startDate),
                  new Date(selectedEvent.endDate)
                )}
              </p>
              <div className="flex mt-10">
                <Link to={`/events/${selectedEvent.slug}`}>
                  <button className="border-2 border-white rounded-full px-8 p-3 text-white">
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UpcomingEvents;
