import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Button from './common/Button';
import BackgroundImage from 'gatsby-background-image';

const Hero: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "images/hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <>
      <BackgroundImage fluid={data.heroImage.childImageSharp.fluid}>
        <div className="w-full flex content-center items-center h-full px-10 py-8 sm:px-20 sm:py-16 from-white to-transparent bg-gradient-to-r">
          <div className="flex">
            <div className="sm:w-1/2">
              <h1 className="text-3xl lg:text-5xl sm:text-left font-bold lg:leading-normal text-primary-dark">
                Growing Avid and Skilled Readers, Writers, and Inquirers
              </h1>
              <h2 className="mt-10 font-semibold text-primary md:text-xl xl:text-2xl">
                Providing Professional Development for Teachers and Schools for
                More than Thirty-Five Years
              </h2>
              <div className="mt-10">
                <Link to={`/services`}>
                  <Button text="Learn More" size="md" />
                </Link>
              </div>
            </div>
            <div className="w-1/2 self-center flex flex-col justify-center"></div>
          </div>
        </div>
      </BackgroundImage>
    </>
  );
};

export default Hero;
