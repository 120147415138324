import React from 'react';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';

interface LoadingButtonProps {
  text: string;
  isLoading: boolean;
  type: 'submit' | 'reset' | 'button' | undefined;
  size?: string;
}
const LoadingButton: React.FC<LoadingButtonProps> = props => {
  return (
    <Button
      text={props.isLoading ? 'Loading' : props.text}
      icon={props.isLoading ? faCircleNotch : null}
      type={props.type || 'button'}
      isSpinner={true}
      size={props.size}
    />
  );
};

export default LoadingButton;
