import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Button from './common/Button';

interface BottomBannerProps {
  title?: string;
  body: string;
  image: any;
  link?: string;
  onClose: () => void;
}

const BottomBanner: React.FC<BottomBannerProps> = props => {
  return (
    <section className="bg-primary p-4 h-auto fixed bottom-4 left-4 right-4 shadow-lg rounded-lg max-w-6xl mx-auto">
      <button className="text-3xl my-auto" onClick={props.onClose}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-blue-200 hover:text-white cursor-pointer"
        />
      </button>
      <div className="flex flex-col w-full mx-auto p-2 sm:p-10">
        <div className="sm:w-1/2 w-full flex self-center">
          <Img
            className="rounded-lg self-center w-full mb-6"
            fluid={props.image.childImageSharp.fluid}
          />
        </div>
        <div className="w-auto md:w-full ml-6 mt-6 sm:mt-0">
          <div className="flex flex-row justify-between">
            {props.title ? (
              <h2 className="text-3xl sm:text-4xl sans-serif">
                <span className="text-blue-200">{props.title}</span>
              </h2>
            ) : null}
          </div>
          <div className="mt-2">
            <p className="text-blue-200 text-lg sm:text-xl sans-serif mt-6">
              {props.body}
            </p>
          </div>
          {props.link ? (
            <div className="mt-10">
              <Link to={props.link}>
                <Button text="Learn More" size="md" />
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default BottomBanner;
