import React from 'react';
import { Link } from 'gatsby';
import ResourceCard from './ResourceCard';
import Button from './common/Button';

interface Resource {
  title: string;
  type: string;
  image: {
    fluid: {
      src: string;
    };
  };
  slug: string;
  body: any;
}

interface ResourcesProps {
  resources: Resource[];
}

const Resources: React.FC<ResourcesProps> = props => {
  return (
    <>
      <div className="container m-auto">
        <div className="flex flex-wrap justify-center">
          {props.resources.map((resource: Resource, i: number) => {
            return (
              <div className="flex m-4" key={i}>
                <ResourceCard
                  title={resource.title}
                  type={resource.type}
                  image={resource.image?.fluid?.src}
                  link={`/resources/${resource.slug}`}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="text-center mt-8">
        <Link to="/resources">
          <Button text="View More Resources" size="md" />
        </Link>
      </div>
    </>
  );
};

export default Resources;
