import React from 'react';
import ServiceCard from './ServiceCard';

interface ServicesProps {
  data: any;
}

const Services: React.FC<ServicesProps> = props => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-24">
      <ServiceCard
        title="Events and Programs"
        body={`<p>Advancing Literacy offers a variety of professional development opportunities for teachers and school leaders. Learn from our staff developers in a variety of formats, virtually and in-person, addressing a range of topics.</p>`}
        image={props.data.card1Image}
        link="/events"
        linkLabel="Events"
      />
      <ServiceCard
        title="Ways to Partner with Us"
        body={`<p>Advancing Literacy staff developers and leaders partner with schools and districts at all stages of literacy instructions and offer a variety of professional development options.</p>`}
        image={props.data.card2Image}
        link="/services"
        linkLabel="Services"
      />
      <ServiceCard
        title="Resources"
        body={`<p>Advancing Literacy offers many free resources, from videos to booklists to assessment tools. Partner schools can access additional materials via their member login.</p>`}
        image={props.data.card3Image}
        link="/resources"
        linkLabel="Resources"
      />
    </div>
  );
};

export default Services;
